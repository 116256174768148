export default class UserTableItem {
    userId = 0;
    orgCode = 0;
    orgCodeName = "";
    userWxMiniOpenId = "";
    userWxMpOpenId = "";
    userWxUnionId = "";
    deptId = 0;
    deptIdName = "";
    postId = 0;
    postIdName = "";
    userStatus = 0;
    userCode = "";
    userName = "";
    userPhone = "";
    userPass = "";
    userIcon = "";
    realName = "";
    roleIdList = "";
    roleIdListName = "";
    createTime = "";
    createName = "";
    modifyTime = "";
    modifyName = "";
}
